export default {
  props: ["nameScreen"],
  data() {
    return {
      texts:'',
      menu: false,
      sSearch: "",
      dates: null,
      menuDateStart: false,
      menuDateEnd: false,
      dateStart:"",
      dateEnd:"",
      select: { texto: 'Todo', valor: '01' },
      items: [
        { texto: 'Todos', valor: '01' },
        { texto: 'prueba1', valor: '02' },
        { texto: 'prueba2', valor: '03' },
        { texto: 'prueba3', valor: '04' },
        { texto: 'prueba4', valor: '05' },
      ],
    };
  },
  beforeMount() {
    // vacia todos los filtros al momento de entrar al componente
    this.$store.commit("setSearch", "");
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    this.texts = FILE.ordersTexts[this.selectLanguage];

  },
  methods: {

  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
      }
    },
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
    dateStart: function () {
      if (this.dateStart === null) {
        this.dateStart = "", this.dateEnd = ""
      }
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      if (this.dateEnd === null) {
        this.dateStart = "", this.dateEnd = ""
      }
      this.$store.commit("setEndDate", this.dateEnd);
    },
  },
};